import React, { useCallback, useEffect, useState } from 'react'
import './GrammarContent.scss'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetWord } from '../../../../../../utils';
import { Button, Input, Modal, Table } from 'antd';
import AddGrammar from './components/AddGrammar';
import CommentAdded from './components/CommentAdded';
import TrueExample from './components/TrueExample';
import { useDispatch, useSelector } from 'react-redux';
import { getLessonContent } from '../../../../../../stores/lessonContentdata';
import FalseExample from './components/FalseExample';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { getInitUnit } from '../../../../../../stores/site';


const GrammarContent = () => {
    const [isUpdate, setIsUpdate] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCommentAdded, setIsCommentAdded] = useState(false);
    const [isFalseExample, setIsFalseExample] = useState(false);
    const [isTrueExample, setIsTrueExample] = useState(false);
    const [selectData, setselectData] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const site = useSelector(state => state.site);
    const [search, setSearch] = useState(null);
    const searchText = useCallback((e) => {
        setSearch(e.target.value)
    });


    const grammers = useSelector(state => state.lessonContentdata.grammers);
    const name = useSelector((state) => state.lessonContentdata.name);
    const dispatch = useDispatch();
    const { lessonRoadId } = useParams();

    useEffect(() => {
        if (!site.unitName) {
            dispatch(getInitUnit());
        }
    }, [site, dispatch])

    useEffect(() => {
        dispatch(getLessonContent(lessonRoadId))
    }, [dispatch, lessonRoadId, isUpdate])

    const List = [
        {
            title: GetWord(""),
            dataIndex: 'icon',
            render: (username, data) =>
                <i className='icon-reorder GrammarContent-BodyArea-List-Icon' />,
        },

        {
            title: GetWord("Header"),
            dataIndex: 'title',
            render: (header, data) =>
                <p className='GrammarContent-BodyArea-List-Name'>{header}</p>
        },
        {
            title: GetWord('Explanation'),
            dataIndex: 'description',
            render: (text, record) =>
                <Link className='GrammarContent-BodyArea-List-Explanation' onClick={() => commentAdded(record)}>
                    <i className='icon-more_horiz' />
                    <p className=''>{text.length > 0 ? GetWord("DescriptionAdded") : "NoDescriptionAdded"}</p>
                </Link>

        },
        {
            title: GetWord('True Example'),
            dataIndex: 'sample_sentece',
            render: (text, record) =>
                <Link className='GrammarContent-BodyArea-List-TrueExample' onClick={() => trueExample(record)}>
                    <i className='icon-more_horiz' />
                    <p className=''>{text.length > 0 ? GetWord("Example") : "NoExample"}</p>
                </Link>

        },
        {
            title: GetWord('False Example'),
            dataIndex: 'incorrect_sentence',
            render: (text, record) =>
                <Link className='GrammarContent-BodyArea-List-FalseExample' onClick={() => falseExample(record)}>
                    <i className='icon-more_horiz' />
                    <p className=''>{text.length > 0 ? GetWord("Example") : "NoExample"}</p>
                </Link>

        },

        {
            title: '',
            dataIndex: 'button',
            render: (text, record) =>
                <div className='GrammarContent-BodyArea-List-ButtonArea'>
                    <button className='GrammarContent-BodyArea-List-ButtonArea-DeleteButton' onClick={() => onDelete(record)}>
                        <i className='icon-dangerous GrammarContent-BodyArea-List-ButtonArea-DeleteButton-Icon' />
                        <p className='GrammarContent-BodyArea-List-ButtonArea-DeleteButton-Text'>{GetWord("Delete")}</p>
                    </button>
                    <button className='GrammarContent-BodyArea-List-ButtonArea-EditButton' onClick={() => GrammarAdd(record)}>
                        <i className='icon-edit GrammarContent-BodyArea-List-ButtonArea-EditButton-Icon' />
                        <p className='GrammarContent-BodyArea-List-ButtonArea-EditButton-Text'>{GetWord("Edit")}</p>
                    </button></div>
        },
    ];

    const GrammarAdd = useCallback((value) => {
        setselectData(value);
        setIsModalOpen(true);
    }, []);
    const noCancel = () => {
        setIsModalOpen(false);
        setIsCommentAdded(false);
        setIsTrueExample(false);
        setIsFalseExample(false);
    };
    const commentAdded = useCallback((value) => {
        setselectData(value)
        setIsCommentAdded(true);
    }, []);

    const trueExample = useCallback((value) => {
        setselectData(value)
        setIsTrueExample(true);
    }, []);

    const onDelete = useCallback((value) => {
        setselectData(value)
        setIsDeleteModalOpen(true);
    }, []);

    const falseExample = useCallback((value) => {
        setselectData(value)
        setIsFalseExample(true);
    }, []);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    }
    return (
        <div className='GrammarContent'>

            <div className='GrammarContent-HeaderArea'>
                <div className='GrammarContent-HeaderArea'>
                    <div className='GrammarContent-HeaderArea-PageName'><Link className="GrammarContent-prevlink" onClick={handleGoBack}>{GetWord("Lessons")} - {site.unitName} - {name} - {GetWord("Grammar")}</Link></div>
                </div>
                <div className='GrammarContent-HeaderArea-SearchFilter'>
                    <Input onChange={searchText}
                        addonBefore={<i className='icon-search GrammarContent-HeaderArea-SearchFilter-Search-Icon' />} placeholder={GetWord(" Search for a grammer")} />
                    <div className='GrammarContent-HeaderArea-SearchFilter-Filter'>
                        <Button onClick={() => dispatch(getLessonContent(lessonRoadId, search ? "search=" + search : ""))}
                            className='GrammarContent-HeaderArea-SearchFilter-Filter-Style'>
                            <p className='GrammarContent-HeaderArea-SearchFilter-Filter-Text'> {GetWord("Filter")}   <i className='icon-filter_alt GrammarContent-HeaderArea-SearchFilter-Filter-Icon' /></p>
                        </Button>
                    </div>
                </div>
            </div>

            <>
                <Modal destroyOnClose title="" open={isCommentAdded} onCancel={noCancel} closable={false} className='GrammarContent'
                    footer={null}>
                    <CommentAdded selectData={selectData} setIsCommentAdded={setIsCommentAdded} setIsUpdate={setIsUpdate} />
                </Modal>

                <Modal destroyOnClose title="" open={isTrueExample} onCancel={noCancel} closable={false} className='GrammarContent'
                    footer={null}>
                    <TrueExample selectData={selectData} setIsTrueExample={setIsTrueExample} setIsUpdate={setIsUpdate} />
                </Modal>

                <Modal destroyOnClose title="" open={isFalseExample} onCancel={noCancel} closable={false} className='GrammarContent'
                    footer={null}>
                    <FalseExample selectData={selectData} setIsFalseExample={setIsFalseExample} setIsUpdate={setIsUpdate} />
                </Modal>
            </>

            <div className='GrammarContent-BodyArea'>
                <div className='dialogue-BodyArea-List'>
                    <Table
                        columns={List}
                        dataSource={grammers}
                        scroll={{ x: 'fit-content' }}
                    />
                    <div className='GrammarContent-BodyArea-AddGrammar-Button'>
                        <Button className='GrammarContent-BodyArea-AddGrammar-Button-Style' type="primary" onClick={() => GrammarAdd(null)}>
                            <p className='GrammarContent-BodyArea-AddGrammar-Button-Text'>{GetWord("Add Grammer")}</p>
                            <div className='GrammarContent-BodyArea-AddGrammar-Button-Icon' >
                                <i className='icon-add' />
                            </div>
                        </Button>

                        <>
                            <Modal destroyOnClose title="" open={isModalOpen} onCancel={noCancel} closable={false} className='GrammarContent'
                                footer={null}>
                                <AddGrammar selectData={selectData} setIsModalOpen={setIsModalOpen} setIsUpdate={setIsUpdate} />
                            </Modal>
                        </>
                    </div>
                </div>
            </div>
            <DeleteModal setIsModalOpen={setIsDeleteModalOpen} isModalOpen={isDeleteModalOpen} url="lessons/roads/grammer" setIsReflesh={setIsUpdate} seletecedId={selectData?.id} />
        </div>
    )
}

export default GrammarContent