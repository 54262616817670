import React, { useCallback, useEffect, useState } from 'react'
import './WordsContent.scss'
import { Button, Checkbox, Input, Modal, Radio, Space, Table } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetWord } from '../../../../../../utils';
import ImageAdded from './components/ImageAdded';
import WordAdded from './components/WordAdded';
import { useDispatch, useSelector } from 'react-redux';
import { getLessonContent } from '../../../../../../stores/lessonContentdata';
import AddWordImage from './components/AddWordImage';
import CommentAdded from './components/CommentAdded';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { getInitUnit } from '../../../../../../stores/site';

const WordsContent = () => {
    const [isUpdate, setIsUpdate] = useState(false);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [isWordAdded, setisWordAdded] = useState(false);
    // const [isImageAdded, setisImageAdded] = useState(false);
    const [isAddWordImage, setIsAddWordImage] = useState(false);
    const [isCommentAdded, setIsCommentAdded] = useState(false);
    const [selectData, setselectData] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const site = useSelector(state => state.site);
    const [search, setSearch] = useState(null);
    const searchText = useCallback((e) => {
        setSearch(e.target.value);
    })

    const words = useSelector(state => state.lessonContentdata.words)
    const name = useSelector((state) => state.lessonContentdata.name)

    const { lessonRoadId } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!site.unitName) {
            dispatch(getInitUnit());
        }
    }, [site, dispatch])

    useEffect(() => {
        dispatch(getLessonContent(lessonRoadId))
    }, [dispatch, lessonRoadId, isUpdate])

    const commentAdded = useCallback((value) => {
        setselectData(value)
        setIsCommentAdded(true);
    }, []);

    const List = [
        {
            title: GetWord(""),
            dataIndex: 'icon',
            render: (username, data) =>
                <i className='icon-reorder WordsContent-BodyArea-List-Icon' />,
        },
        {
            title: GetWord('Header'),
            dataIndex: 'description',
            render: (text, record) =>
                <Link className='RulesContent-BodyArea-List-Explanation' onClick={() => commentAdded(record)}>
                    <i className='icon-more_horiz' />
                    <p className=''>{text.length > 0 ? GetWord("DescriptionAdded") : "NoDescriptionAdded"}</p>
                </Link>

        },
        {
            title: GetWord("Example"),
            dataIndex: 'word',
            render: (text, record) => (
                record.image_url !== null && record.image_url !== "" ? (
                    <>
                        <Link className='WordsContent-ImageAdded-Button' onClick={() => packageAdd(record)}>
                            <i className='icon-image WordsContent-ImageAdded-Button-Text' />
                            <p className='WordsContent-ImageAdded-Button-Text'>{GetWord("VisualAdded")}</p>
                        </Link>
                    </>

                ) : (
                    <>{text}</>
                )
            ),
        },
        {
            title: GetWord("Translation"),
            dataIndex: 'translate',
            render: (text) =>
                <p className='WordsContent-BodyArea-List-NameArea-Name'>{text}</p>,
        },

        {
            title: '',
            dataIndex: 'button',
            render: (text, record) =>
                <div className='WordsContent-BodyArea-List-ButtonArea'>
                    <button className='WordsContent-BodyArea-List-ButtonArea-DeleteButton' onClick={() => onDelete(record)} >
                        <i className='icon-dangerous WordsContent-BodyArea-List-ButtonArea-DeleteButton-Icon' />
                        <p className='WordsContent-BodyArea-List-ButtonArea-DeleteButton-Text'>{GetWord("Delete")}</p>
                    </button>
                    <button className='WordsContent-BodyArea-List-ButtonArea-EditButton' onClick={() => packageAdd(record)}>
                        <i className='icon-edit WordsContent-BodyArea-List-ButtonArea-EditButton-Icon' />
                        <p className='WordsContent-BodyArea-List-ButtonArea-EditButton-Text'>{GetWord("Edit")}</p>
                    </button></div>
        },
    ];



    const packageAdd = useCallback((value) => {
        setselectData(value)
        setIsAddWordImage(true);
    }, []);


    const noCancel = () => {
        setIsCommentAdded(false);
        setIsAddWordImage(false);
    };

    const onDelete = useCallback((value) => {
        setselectData(value)
        setIsDeleteModalOpen(true);
    }, []);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
        <div className='WordsContent'>

            <div className='WordsContent-HeaderArea'>
                <div className='WordsContent-HeaderArea'>
                    <div className='WordsContent-HeaderArea-PageName'><Link className="WordsContent-prevlink" onClick={handleGoBack}>{GetWord("Lessons")} - {site.unitName} - {name}- {GetWord("Words")}</Link></div>
                </div>
                <div className='WordsContent-HeaderArea-SearchFilter'>
                    <Input onChange={searchText}
                        addonBefore={<i className='icon-search WordsContent-HeaderArea-SearchFilter-Search-Icon' />} placeholder={GetWord(" Search for a word")} />
                    <div className='WordsContent-HeaderArea-SearchFilter-Filter'>
                        <Button onClick={() => dispatch(getLessonContent(lessonRoadId, search ? "search=" + search : ""))}
                            className='WordsContent-HeaderArea-SearchFilter-Filter-Style'>
                            <p className='WordsContent-HeaderArea-SearchFilter-Filter-Text'> {GetWord("Filter")}   <i className='icon-filter_alt WordsContent-HeaderArea-SearchFilter-Filter-Icon' /></p>
                        </Button>
                    </div>
                </div>
            </div>

            <Modal destroyOnClose title="" open={isCommentAdded} onCancel={noCancel} closable={false} className='WordsContent'
                footer={null}>
                <CommentAdded selectData={selectData} setIsModalOpen={setIsCommentAdded} setIsUpdate={setIsUpdate} />
            </Modal>



            <Modal destroyOnClose={isAddWordImage} title="" open={isAddWordImage} onCancel={noCancel} closable={false} className='WordsContent'
                footer={null}>
                <AddWordImage selectedData={selectData} setIsUpdate={setIsUpdate} setIsAddWordImage={setIsAddWordImage} />
            </Modal>

            <div className='WordsContent-BodyArea'>
                <div className='WordsContent-BodyArea-List'>
                    <Table
                        columns={List}
                        dataSource={words}
                        scroll={{ x: 'fit-content' }}

                    />
                </div>

                <div className='WordsContent-HeaderArea-AddWords-Button'>
                    <>
                        <Button className='WordsContent-HeaderArea-AddWords-Button-Style' type="primary" onClick={() => packageAdd(null)}>
                            <p className='WordsContent-HeaderArea-AddWords-Button-Text'>{GetWord("Add Example")}</p>
                            <div className='WordsContent-HeaderArea-AddWords-Button-Icon'>
                                <i className='icon-add' />
                            </div>
                        </Button>
                    </>
                </div>
            </div>
            <DeleteModal setIsModalOpen={setIsDeleteModalOpen} isModalOpen={isDeleteModalOpen} url="lessons/roads/words" setIsReflesh={setIsUpdate} seletecedId={selectData?.id} />
        </div>
    )
}

export default WordsContent