import { createSlice } from "@reduxjs/toolkit";
import { getLocalstorage, setLocalstorage } from "../utils";

export const site = createSlice({
  name: "site",
  initialState: {
    dark: false,
    language: "tr",
    sideBtn: true,
    pageName: "",
    isSelectedLang: true,
    selectedLang: 0,
    selectedTranslateLang: 0,
    unitName: "",
    dialogName: ""
  },
  reducers: {
    setDarkMode: (state) => {
      state.dark = !state.dark;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setSideBtn: (state) => {
      state.sideBtn = !state.sideBtn;
    },
    setPageName: (state, action) => {
      state.pageName = action.payload;
    },

    setSelectedLang: (state, action) => {
      state.selectedLang = action.payload;
      setLocalstorage("selectedLang", action.payload);
    },

    setSelectedTranslateLang: (state, action) => {
      state.selectedTranslateLang = action.payload;
      setLocalstorage("selectedTranslateLang", action.payload);

    },

    setIsSelectedLang: (state, action) => {
      state.isSelectedLang = action.payload;
      setLocalstorage("isSelectedLang", action.payload);
    },

    setIUnitName: (state, action) => {
      state.unitName = action.payload;
      setLocalstorage("UnitName", action.payload);

    },

    setDialogName: (state, action) => {
      state.dialogName = action.payload;
    }

  },
});

export const { setDarkMode, setLanguage, setSideBtn, setPageName, setSelectedLang, setSelectedTranslateLang, setIsSelectedLang, setIUnitName, setDialogName } = site.actions;

export default site.reducer;

export function getInitLang() {
  return async (dispatch) => {
    const selectedTranslateLang = getLocalstorage("selectedTranslateLang");
    const selectedLang = getLocalstorage("selectedLang");
    const isSelectedLang = getLocalstorage("isSelectedLang");

    if (selectedTranslateLang && selectedLang && !isSelectedLang) {
      dispatch(setSelectedLang(selectedLang));
      dispatch(setSelectedTranslateLang(selectedTranslateLang));
      dispatch(setIsSelectedLang(isSelectedLang));
    }
  }
}

export function getInitUnit() {
  return async (dispatch) => {
    const unitName = getLocalstorage("UnitName");

    if (unitName) {
      dispatch(setIUnitName(unitName));
    }
  }
}
