import React, { useCallback, useEffect, useState } from 'react'
import './ExerciseContent.scss'
import { Button, Checkbox, Input, Modal, Radio, Space, Table } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetWord } from '../../../../../../utils';
import ExerciseAdded from './components/ExerciseAdded';
import Service from '../../../../../../Service';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { getInitUnit } from '../../../../../../stores/site';

const ExerciseContent = () => {
    const [loading, setLoading] = useState(true);
    const [listData, setListData] = useState([]);
    const { lessonId, lessonRoadId } = useParams();
    const [isDeleteOpenModal, setIsDeleteModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [isReflesh, setIsReflesh] = useState(false);
    const [isAddExercise, setIsAddExercise] = useState(false);
    const [search, setSearch] = useState(null);
    const searchText = useCallback((e) => {
        setSearch(e.target.value);
    })

    const site = useSelector(state => state.site);
    const name = useSelector((state) => state.lessonContentdata.name);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!site.unitName) {
            dispatch(getInitUnit());
        }
    }, [site, dispatch])

    const getExercise = useCallback((search) => {
        const service = new Service();
        service.AdminService.GetExercise(lessonId, search ? "search=" + search : "").then((res) => {
            if (!res.error && res.data) {
                var filterList = res.data.filter((item) => item.road_id === parseInt(lessonRoadId))

                if (filterList) {
                    const combinedArray = [].concat.apply([], filterList.map(item => item.exercises_question));
                    setListData(combinedArray);
                }
            }
        })
    }, [lessonId, lessonRoadId]);


    useEffect(() => {
        getExercise();
    }, [getExercise, isReflesh])

    const List = [
        {
            title: GetWord(""),
            dataIndex: 'icon',
            render: (username, data) =>
                <i className='icon-reorder ExerciseContent-BodyArea-List-Icon' />,
        },
        {
            title: GetWord("Type"),
            dataIndex: 'type',
            render: (text, record) => (
                text === 'listening' ? (
                    <>
                        <p className='ExerciseContent-TypeIcon'>
                            <i className='icon-graphic_eq ' />

                            <p className=''>{GetWord(text)}</p>
                        </p>
                    </>

                )
                    :
                    text === 'gap_filing' ? (
                        <>
                            <p className='ExerciseContent-TypeIcon'>
                                <i className='icon-space_bar' />
                                <p className=''>{GetWord(text)}</p>
                            </p>
                        </>

                    )
                        :
                        (
                            <>{GetWord(text)}</>
                        )
            ),
        },
        {
            title: GetWord("Word"),
            dataIndex: 'word',
            render: (text, record) =>
                <p className='ExerciseContent-BodyArea-List-NameArea-Name'>{
                    record.type === "listening" ? <audio controls>
                        <source src={record.question} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio> : record.question

                }</p>,
        },
        {
            title: GetWord("Answer True"),
            dataIndex: 'answerTrue',
            render: (text, record) =>
                <p className='ExerciseContent-BodyArea-List-NameArea-Name'>{record?.answer[0]}</p>,
        },

        {
            title: GetWord("Answer False"),
            dataIndex: 'answerFalse',
            render: (text, record) =>
                <p className='ExerciseContent-BodyArea-List-NameArea-Name'>{record?.answer[1]}</p>,
        },

        {
            title: GetWord("Answer False"),
            dataIndex: 'answerFalseTwo',
            render: (text, record) =>
                <p className='ExerciseContent-BodyArea-List-NameArea-Name'>{record?.answer[2]}</p>,
        },

        {
            title: '',
            dataIndex: 'button',
            render: (text, record) =>
                <div className='ExerciseContent-BodyArea-List-ButtonArea'>
                    <button className='ExerciseContent-BodyArea-List-ButtonArea-DeleteButton' onClick={() => deleteData(record)}>
                        <i className='icon-dangerous ExerciseContent-BodyArea-List-ButtonArea-DeleteButton-Icon' />
                        <p className='ExerciseContent-BodyArea-List-ButtonArea-DeleteButton-Text'>{GetWord("Delete")}</p>
                    </button>
                    <button className='ExerciseContent-BodyArea-List-ButtonArea-EditButton' onClick={() => packageAdd(record)} >
                        <i className='icon-edit ExerciseContent-BodyArea-List-ButtonArea-EditButton-Icon' />
                        <p className='ExerciseContent-BodyArea-List-ButtonArea-EditButton-Text'>{GetWord("Edit")}</p>
                    </button></div>
        },
    ];



    const packageAdd = useCallback((value) => {
        setSelectedData(value)
        setIsAddExercise(true);
    }, []);


    const deleteData = useCallback((value) => {
        setSelectedData(value);
        setIsDeleteModal(true);
    }, []);


    const noCancel = () => {
        setIsAddExercise(false);
    };


    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
        <div className='ExerciseContent'>

            <div className='ExerciseContent-HeaderArea'>
                <div className='ExerciseContent-HeaderArea'>
                    <div className='ExerciseContent-HeaderArea-PageName'><Link className="ExerciseContent-prevlink" onClick={handleGoBack}> {GetWord("Lessons")} - {site.unitName} - {name} - {GetWord("Exercice")}</Link></div>
                </div>
                <div className='ExerciseContent-HeaderArea-SearchFilter'>
                    <Input onChange={searchText}
                        addonBefore={<i className='icon-search ExerciseContent-HeaderArea-SearchFilter-Search-Icon' />} placeholder={GetWord(" Search for a exercise")} />
                    <div className='ExerciseContent-HeaderArea-SearchFilter-Filter'>
                        <Button onClick={() => (getExercise(search))}
                            className='ExerciseContent-HeaderArea-SearchFilter-Filter-Style'>
                            <p className='ExerciseContent-HeaderArea-SearchFilter-Filter-Text'> {GetWord("Filter")}   <i className='icon-filter_alt ExerciseContent-HeaderArea-SearchFilter-Filter-Icon' /></p>
                        </Button>
                    </div>
                </div>
                <div className='ExerciseContent-HeaderArea-AddWords'>

                </div>
            </div>

            <Modal destroyOnClose={isAddExercise} title="" open={isAddExercise} onCancel={noCancel} closable={false} className='ExerciseContent'
                footer={null}>
                <ExerciseAdded setIsReflesh={setIsReflesh} setIsAddExercise={setIsAddExercise} selectedData={selectedData} />
            </Modal>




            <div className='ExerciseContent-BodyArea'>
                <div className='ExerciseContent-BodyArea-List'>
                    <Table
                        columns={List}
                        dataSource={listData}
                        scroll={{ x: 'fit-content' }}
                    />
                </div>

                <div className='ExerciseContent-HeaderArea-AddWords-Button'>
                    <>
                        <Button className='ExerciseContent-HeaderArea-AddWords-Button-Style' type="primary" onClick={() => packageAdd(null)}>
                            <p className='ExerciseContent-HeaderArea-AddWords-Button-Text'>{GetWord("Add Exercise")}</p>
                            <div className='ExerciseContent-HeaderArea-AddWords-Button-Icon'>
                                <i className='icon-add' />
                            </div>
                        </Button>

                    </>
                </div>
            </div>

            <DeleteModal isModalOpen={isDeleteOpenModal} setIsModalOpen={setIsDeleteModal} seletecedId={selectedData?.id} setIsReflesh={setIsReflesh} url="exercise/questions" />
        </div >
    )
}

export default ExerciseContent