import React, { useCallback, useEffect, useState } from 'react'
import './LessonsRoads.scss'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Modal, Radio, Space, Table } from 'antd';
import LoadingPage from '../../../../shared/LoadingPage/LoadingPage';
import Service from '../../../../../Service';
import { GetWord } from '../../../../../utils';
import LessonsRoadsAddEdit from './components/LessonsRoadsAddEdit';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { getInitUnit } from '../../../../../stores/site';

const LessonsRoads = () => {
    const [loading, setLoading] = useState(true);
    const [listData, setListData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [updateList, setUpdateList] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [value, setValue] = useState(1);
    const site = useSelector(state => state.site);
    const [search, setSearch] = useState(null);
    const dispatch = useDispatch();
    const searchText = useCallback((e) => {
        setSearch(e.target.value);
    })

    const { lessonId } = useParams();

    useEffect(() => {
        if (!site.unitName) {
            dispatch(getInitUnit());
        }
    }, [site, dispatch])

    const getLessonsRoads = useCallback((search) => {
        setLoading(true);
        const service = new Service();
        service.AdminService.getLessonRoads(lessonId + (search ? "?search=" + search : "")).then((res) => {
            // service.AdminService.getLessonRoads(lessonId).then((res) => {
            if (!res.error && res.data) {
                setListData(res.data);
                setLoading(false);
            }
        });
    }, [lessonId]);

    useEffect(() => {
        getLessonsRoads();
    }, [getLessonsRoads, updateList])


    const List = [
        {
            title: GetWord("Name"),
            dataIndex: 'name',
            render: (username, data) =>
                <p className='LessonsRoads-BodyArea-List-NameArea-Name'>{username}</p>,
        },
        {
            title: GetWord("Subject"),
            dataIndex: 'subject',
            render: (username, data) =>
                <p className='LessonsRoads-BodyArea-List-NameArea-Name'>{username}</p>,
        },
        {
            title: GetWord("Inside Contents"),
            dataIndex: 'content',
            render: (text, record) => {
                // record.name üzerinden ismi alabilir ve linkTo'yu belirleyebilirsiniz

                return (
                    <Link className='LessonsRoads-BodyArea-List-Content' to={"LessonContent/" + record.id}>
                        <i className='icon-folder LessonsRoads-BodyArea-List-Content-Icon' />
                        <p className='LessonsRoads-BodyArea-List-Content-Text'>{text}</p>
                    </Link>
                );
            }
        },

        {
            title: '',
            dataIndex: 'button',
            render: (text, record) =>
                <div className='LessonsRoads-BodyArea-List-ButtonArea'>
                    <button className='LessonsRoads-BodyArea-List-ButtonArea-DeleteButton' onClick={() => LessonRoadsDelete(record)} >
                        <i className='icon-dangerous LessonsRoads-BodyArea-List-ButtonArea-DeleteButton-Icon' />
                        <p className='LessonsRoads-BodyArea-List-ButtonArea-DeleteButton-Text'>{GetWord("Delete")}</p>
                    </button>
                    <button className='LessonsRoads-BodyArea-List-ButtonArea-EditButton' onClick={() => LessonRoadsEdit(record)}>
                        <i className='icon-edit LessonsRoads-BodyArea-List-ButtonArea-EditButton-Icon' />
                        <p className='LessonsRoads-BodyArea-List-ButtonArea-EditButton-Text'>{GetWord("Edit")}</p>
                    </button></div>
        },
    ];

    const packageAdd = () => {
        setSelectedData(null)
        setIsModalOpen((prev) => !prev);
    };

    const noCancel = () => {
        setIsModalOpen(false);
    };

    const LessonRoadsEdit = useCallback((value) => {
        setSelectedData(value);
        setIsModalOpen((prev) => !prev);
    }, [])

    const LessonRoadsDelete = useCallback((value) => {
        setSelectedData(value);
        setIsDeleteModalOpen((prev) => !prev);
    }, [])

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
        <div className='LessonsRoads'>

            <div className='LessonsRoads-HeaderArea'>
                <div className='LessonsRoads-HeaderArea'>
                    <div className='LessonsRoads-HeaderArea-PageName'><Link className="LessonsRoads-prevlink" onClick={handleGoBack}>{GetWord("Lessons")} -  {site.unitName}</Link> </div>
                </div>
                <div className='LessonsRoads-HeaderArea-SearchFilter'>
                    <Input onChange={searchText}
                        addonBefore={<i className='icon-search LessonsRoads-HeaderArea-SearchFilter-Search-Icon' />} placeholder={GetWord("Search for a unit")} />
                    <div className='LessonsRoads-HeaderArea-SearchFilter-Filter'>
                        <Button onClick={() => getLessonsRoads(search)}
                            className='LessonsRoads-HeaderArea-SearchFilter-Filter-Style'>
                            <p className='LessonsRoads-HeaderArea-SearchFilter-Filter-Text'> {GetWord("Filter")}   <i className='icon-filter_alt LessonsRoads-HeaderArea-SearchFilter-Filter-Icon' /></p>
                        </Button>
                    </div>
                </div>
                <div className='LessonsRoads-HeaderArea-AddContent'>
                    <div className='LessonsRoads-HeaderArea-AddContent-Button'>
                        <>
                            <Button className='LessonsRoads-HeaderArea-AddContent-Button-Style' type="primary" onClick={packageAdd}>
                                <p className='LessonsRoads-HeaderArea-AddContent-Button-Text'>{GetWord("Create Content")}</p>
                                <div className='LessonsRoads-HeaderArea-AddContent-Button-Icon'>
                                    <i className='icon-add' />
                                </div>
                            </Button>

                            <Modal footer={null} onCancel={noCancel} title="" open={isModalOpen} destroyOnClose closable={false} className='LessonsRoads'
                            >
                                <LessonsRoadsAddEdit selectedData={selectedData} lessonId={lessonId} setUpdateList={setUpdateList} setIsModalOpen={setIsModalOpen} />
                            </Modal>
                        </>

                    </div>
                </div>
            </div>


            <div className='LessonsRoads-BodyArea'>
                <div className='LessonsRoads-BodyArea-List'>
                    <Table
                        loading={{ spinning: loading, indicator: <LoadingPage small={true} /> }}
                        columns={List}
                        dataSource={listData}
                        scroll={{ x: 'fit-content' }}
                    />
                </div>

            </div>
            <DeleteModal setIsModalOpen={setIsDeleteModalOpen} isModalOpen={isDeleteModalOpen} seletecedId={selectedData?.id} isreflesh={updateList} setIsReflesh={setUpdateList} url="lesson/road" />
        </div>
    )
}

export default LessonsRoads