import { Button, Form, Input, InputNumber, Radio, Space } from 'antd'
import React, { useCallback, useState } from 'react'
import { GetWord, randomGuid } from '../../../../../utils';
import '../Packages.scss'
import Service from '../../../../../Service';
import { toast } from 'react-toastify';

const PacketAddUpdateForm = ({ setIsModalOpen, defaultValue, setIsReflesh, isFreePacketAvailable }) => {
    const [form] = Form.useForm();
    const [isFreePlan, setIsFreePlan] = useState(false);

    const onFinish = useCallback((value) => {
        // value["product_id"] = randomGuid();
        const service = new Service();
        if (defaultValue) {
            value["product_id"] = defaultValue.product_id;
            service.AdminService.updatePackages(value, defaultValue.id).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsReflesh((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            });
        } else {
            service.AdminService.postPackages(value).then((res) => {
                if (!res.error && res.data) {
                    toast.success(GetWord("Added successfully."));
                    setIsModalOpen((prev) => !prev);
                    setIsReflesh((prev) => !prev);
                } else {
                    toast.error(res.message)
                }
            });
        }

    }, [setIsModalOpen, setIsReflesh]);

    const onValuesChange = useCallback((value, fullValue) => {
        if (fullValue.type === "free") {
            setIsFreePlan(true);
            form.setFieldsValue({
                price: 0,
            });

        } else {
            setIsFreePlan(false);
        }
    }, [form])
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={defaultValue}
            className='packages-AddPackage'
            onValuesChange={onValuesChange}
        >
            <Form.Item name="product_id" className='packages-AddPackage-PackageName' rules={[
                {
                    required: true,
                    message: GetWord("Product Id"),
                },
            ]}>
                <Input placeholder={GetWord("Product id")} className='packages-AddPackage-Input' />
            </Form.Item>
            <Form.Item name="name" className='packages-AddPackage-PackageName' rules={[
                {
                    required: true,
                    message: GetWord("plsName"),
                },
            ]}>
                <Input placeholder={GetWord("Package Name")} className='packages-AddPackage-Input' />
            </Form.Item>
            <Form.Item className='packages-AddPackage-LessonDialogue' name="type" rules={[
                {
                    required: true,
                    message: GetWord("plsType"),
                },
            ]}>
                <Radio.Group  >
                    <Space direction="horizontal">
                        <Radio value="unit" >{GetWord("Unit")}</Radio>
                        <Radio value="dialog" >{GetWord("Dailog")}</Radio>
                        <Radio value="yearly" >{GetWord("Yearly")}</Radio>
                        <Radio value="monthly" >{GetWord("Monthly")}</Radio>
                        <Radio disabled={isFreePacketAvailable} value="free" >{GetWord("Free")}</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>

            <div className='packages-AddPackage-StartEnd' >
                <Form.Item className='packages-AddPackage-StartEnd-Start' rules={[
                    {
                        required: true,
                        message: GetWord("plsUnitsNumber"),
                    },
                ]} label={<p className='packages-AddPackage-StartEnd-Start-Title'>{GetWord("Unit Number")}</p>} name="included_units">
                    <InputNumber placeholder={GetWord("Unit Number")} className='packages-AddPackage-StartEnd-Start' />
                </Form.Item>
                <Form.Item className='packages-AddPackage-StartEnd-End' rules={[
                    {
                        required: true,
                        message: GetWord("plsDialogsNumber"),
                    },
                ]} label={<p className='packages-AddPackage-StartEnd-End-Title'>{GetWord("Dialog Number")}</p>
                } name="included_dialogs">
                    <InputNumber placeholder={GetWord("Dialog Number")} className='packages-AddPackage-StartEnd-End' />
                </Form.Item>
            </div>

            <Form.Item className='packages-AddPackage-Price' rules={[
                {
                    required: true,
                    message: GetWord("plsPrice"),
                },
            ]} name="price">
                <InputNumber disabled={isFreePlan} addonAfter="₺" placeholder={GetWord("Price")} className='packages-Price-Input' />
            </Form.Item>
            <Form.Item>
                <div className='packages-AddPackage-Button'>
                    <Button className='packages-AddPackage-Button-Style' htmlType="submit">
                        <p className='packages-AddPackage-Button-Text'>{GetWord("Add")}  <i className='icon-add' packages-AddPackage-Button-Text /></p>
                    </Button>
                </div>
            </Form.Item>
        </Form>
    )
}

export default PacketAddUpdateForm